<template>
  <div class="resultPage">
    <div v-for="answer in answers" :key="answer.docId" class="resultitem">
      <img :src="answer.image" alt="app" />
      <h2 class="count">{{ answer.count }}</h2>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  getFirestore,
  query,
  collection,
  getDocs
} from "firebase/firestore";
export default {
  name: "Result",
  data() {
    return {
      page: 2,
      answers: [],
      db: {},
    };
  },
  methods: {},
  async created() {
    this.db = getFirestore();
    const q = query(collection(this.db, "test"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let answer = {};
      answer.title = doc.data().title;
      answer.image = doc.data().image;
      answer.count = doc.data().count;
      answer.docId = doc.id;
      answer.flag = false;
      this.answers.push(answer);
    });
  },
};
</script>

<style>
.resultPage {
  display: grid;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.resultitem {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  border: 1px solid gray;
  padding: 1rem;
  box-shadow: 5px 5px 5px gray;
}

.resultitem img {
  height: 200px;
  object-fit: contain;
}

.count{
  color:blue;
  font-size: xx-large;
}
</style>