<template>
  <div class="usersPage">
    <div class="item" v-for="user in users" :key="user.docId">
      <h3>{{ user.name }} ({{ user.activity }})</h3>
      <ul>
        <li v-for="link in user.links" :key="link.title">
          <a :href="link.href">{{ link.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  getFirestore,
  collection,
  query,
  onSnapshot,
} from "@firebase/firestore";
export default {
  name: "Users",
  data() {
    return {
        users:[]
    };
  },
  created() {
    this.db = getFirestore();
    const q = query(collection(this.db, "people"));
    onSnapshot(q, (querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let user = {};
        user.name = doc.data().name;
        user.activity = doc.data().activity;
        user.links = doc.data().links;
        user.docId = doc.id;
        this.users.push(user);
      });
    });
  },
};
</script>

<style>
.usersPage {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.usersPage .item {
  border: 1px solid gray;
  box-shadow: 5px 5px 5px gray;
  border-radius: 20px;
  padding: 20px;
}

ul {
  list-style: none;
  text-align: start;
}

li {
  margin: 1rem;
}
</style>