<template>
  <div class="testPage">
    <div v-for="(answer, index) in answers" :key="answer.docId" class="appitem" :class="{ active: answer.flag }" @click="setAnswer(index)">
      <img :src="answer.image" alt="app" />
      <h3>{{ answer.title }}</h3>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { doc, updateDoc, getDoc, getFirestore, query, collection, getDocs } from "firebase/firestore";
export default {
  name: "Test",
  data() {
    return {
      page: 2,
      answers: [],
      db: {}
    };
  },
  methods: {
    async setAnswer(index) {
      this.answers[index].flag = !this.answers[index].flag;
      const ref = doc(this.db, "test", this.answers[index].docId);
      const docSnap = await getDoc(ref);
      let count = docSnap.data().count;
      if(this.answers[index].flag)
        count++;
      else
        count--;
      await updateDoc(ref, { count: count });
    },
  },
  async created() {
    this.db = getFirestore();
    const q = query(collection(this.db, "test"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let answer = {};
      answer.title = doc.data().title;
      answer.image = doc.data().image;
      answer.count = doc.data().count;
      answer.docId = doc.id;
      answer.flag = false;
      this.answers.push(answer);
    });
  },
};
</script>

<style>
.testPage {
  display: grid;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.appitem {
  border-radius: 30px;
  border: 1px solid gray;
  padding: 1rem;
  opacity: 0.2;
}

.appitem img {
  height: 200px;
  object-fit: contain;
}

.appitem.active {
  box-shadow: 5px 5px 5px gray;
  opacity: 1;
}
</style>