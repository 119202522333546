import { createRouter, createWebHashHistory } from 'vue-router'
import Start from '../views/Start.vue'
import Test from '../views/Test.vue'
import Result from '../views/Result.vue'
import Video from '../views/Video.vue'
import Masterclass from '../views/Masterclass.vue'
import Speakers from '../views/Speakers.vue'
import Links from '../views/Links.vue'
import Users from '../views/Users.vue'

const routes = [
  {
    path: '/',
    name: 'Start',
    component: Start
  },
  {
    path: '/test',
    name: 'Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: Test
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/video',
    name: 'Video',
    component: Video
  },
  {
    path: '/masterclass',
    name: 'Masterclass',
    component: Masterclass
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers
  },
  {
    path: '/links/:docId',
    name: 'Links',
    component: Links,
    props: true
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
