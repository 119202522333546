<template>
  <div class="videoPage">
    <VuePlayerVideo :src="file"/>
  </div>
</template>

<script>
// @ is an alias to /src
import VuePlayerVideo from 'vue3-player-video'
export default {
  name: "Video",
  components:{
    VuePlayerVideo
  },
  data() {
    return {
      file: require("@/assets/video.mp4")
    };
  },
};
</script>

<style>
.videoPage{
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>