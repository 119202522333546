<template>
  <div class="speakersPage">
    <VuePlayerVideo :src="file" />
    <div class="control">
      <button>Антон Новиков, Ростелеком</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VuePlayerVideo from "vue3-player-video";
export default {
  name: "Speakers",
  components: {
    VuePlayerVideo,
  },
  data() {
    return {
      file: require("@/assets/speaker1.mp4"),
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style>
.speakersPage {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>