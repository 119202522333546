<template>
  <div class="masterclassPage">
    <VuePlayerVideo :src="file1" v-if="file==1"/>
    <VuePlayerVideo :src="file2" v-if="file==2"/>
    <div class="control">
      <button @click="video(1)">Видео 1</button>
      <button @click="video(2)">Видео 2</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import VuePlayerVideo from "vue3-player-video";
export default {
  name: "Masterclass",
  components: {
    VuePlayerVideo,
  },
  data() {
    return {
      file: 1,
      file1: require("@/assets/masterclass1.mp4"),
      file2: require("@/assets/masterclass2.mp4"),
    };
  },
  methods: {
    video(value) {
      this.file = value;
    },
  },
  created() {
    this.file = 1;
  },
};
</script>

<style>
.masterclassPage {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.control button{
    margin: 10px;
}
</style>