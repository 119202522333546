<template>
  <div class="startPage">
    <img :src="slide1" alt="slide" v-if="number==0" @click="next">
    <img :src="slide2" alt="slide" v-if="number==1" @click="next">
    <img :src="slide3" alt="slide" v-if="number==2" @click="next">
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Start",
  data() {
    return {
      number: 0,
      slide1: require("@/assets/slide1.png"),
      slide2: require("@/assets/slide2.png"),
      slide3: require("@/assets/slide3.png")
    };
  },
  methods:{
    next(){
      if(this.number < 2)
        this.number++;
      else
        this.$router.push({ name: "Video" });
    }
  },
  created() {
    this.number = 0;
  },
};
</script>

<style>
.startPage{
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.startPage img{
  width: 100%;
  object-fit: contain;
}

.prev{
  width: 100px;
  height: 100px;
  background-image: url("~@/assets/prev.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 20px;
  outline: none;
}

.next{
  width: 100px;
  height: 100px;
  background-image: url("~@/assets/next.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  outline: none;
}
</style>