<template>
  <div class="linksPage">
    <div class="item" v-for="link in links" :key="link.name">
      <h3>{{ link.name }}</h3>
      <ul>
        <li v-for="value in link.links" :key="value.title">
          <input type="checkbox" :value="value" v-model="selectedLinks" class="check" v-if="docId!=0" />
          <a :href="value.href" target="_blanc">{{ value.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  getFirestore,
  updateDoc,
  doc,
  getDoc,
  query,
  collection,
  getDocs
} from "@firebase/firestore";
export default {
  name: "Links",
  props: ["docId"],
  data() {
    return {
      links: [],
      selectedLinks: [],
    };
  },
  watch: {
    selectedLinks: {
      async handler() {
        await updateDoc(doc(this.db, "people", this.docId), {
          links: this.selectedLinks
        });
      },
      deep: true,
    },
  },
  async created() {
    this.db = getFirestore();
    //Список ссылок
    this.links = [];
    const q = query(collection(this.db, "links"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const link = doc.data();
      this.links.push(link);
    });
    //Выбранные пользователем ссылки
    const docRef = doc(this.db, "people", this.docId);
    const docSnap = await getDoc(docRef);
    this.selectedLinks = docSnap.data().links;
  },
};
</script>

<style>
.linksPage {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.linksPage .item {
  border: 1px solid gray;
  box-shadow: 5px 5px 5px gray;
  border-radius: 20px;
  padding: 20px;
}

ul {
  list-style: none;
  text-align: start;
}

li {
  margin: 1rem;
}

.check{
  margin: 1rem;
  transform: scale(1.5);
}
</style>