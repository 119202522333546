<template>
  <nav>
    <router-link class="master" to="/"></router-link>
    <router-link class="test" to="/test">
      <img src="@/assets/test.png" alt="test" />
      <p>Тест</p>
    </router-link>
    <router-link class="result" to="/result">
      <img src="@/assets/result.png" alt="result" />
      <p>Результаты</p>
    </router-link>
    <router-link class="masterclass" to="/masterclass">
      <img src="@/assets/class.png" alt="vasterclass" />
      <p>Мастер-класс</p>
    </router-link>
    <router-link class="speakers" to="/speakers">
      <img src="@/assets/add.png" alt="speakers" />
      <p>Спикеры</p>
    </router-link>
    <router-link
      class="links"
      :to="{ name: 'Links', params: { docId: docRef.id } }"
    >
      <img src="@/assets/new.png" alt="links" />
      <p>Полезный контент</p>
    </router-link>
  </nav>
  <router-view />
  <div class="users">
    <div class="chistov navItem">
      <img src="@/assets/chistov.jpg" alt="Чистов" />
      <h4>Чистов Андрей Александрович</h4>
      <h4>+7 903 212 51 59</h4>
      <h4>chistov@ldpk.ru</h4>
    </div>
    <div class="username navItem">
      <input type="text" placeholder="Ваше имя" v-model="user.name" />
      <input
        type="text"
        placeholder="Сфера деятельности"
        v-model="user.activity"
      />
      <button @click="save">Присоединиться</button>
    </div>
    <!--div class="userslist navItem">
      <h4>Слушатели</h4>
      <div v-for="user in users" :key="user.id" class="user">
        <p>{{ user.name }} ({{ user.activity }})</p>
      </div>
    </div-->
    <div class="navItem">
      <button @click="this.$router.push('/users')">Участники встречи</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  addDoc,
  collection,
  setDoc,
  doc,
  deleteDoc,
  //query,
  //onSnapshot,
} from "@firebase/firestore";
export default {
  name: "App",
  data() {
    return {
      page: 1,
      db: {},
      user: {
        name: "",
        activity: "",
      },
      docRef: {},
      //users: [],
    };
  },
  methods: {
    async save() {
      if (this.docRef.id == 0) {
        this.docRef = await addDoc(collection(this.db, "people"), {
          name: this.user.name,
          activity: this.user.activity,
          links: [],
        });
      } else {
        await setDoc(doc(this.db, "people", this.docRef.id), {
          name: this.user.name,
          activity: this.user.activity,
          links: [],
        });
      }
    },
    async removeUser() {
      console.log(this.docRef.id);
      await deleteDoc(doc(this.db, "people", this.docRef.id));
    },
  },
  created() {
    this.docRef.id = 0;
    window.addEventListener("beforeunload", this.removeUser);
    const firebaseConfig = {
      apiKey: "AIzaSyACaPJkNGz7wViEK1yXRDMV5IImf9P_CD0",
      authDomain: "students-app-9354e.firebaseapp.com",
      projectId: "students-app-9354e",
      storageBucket: "students-app-9354e.appspot.com",
      messagingSenderId: "51399176797",
      appId: "1:51399176797:web:761c67edf08c67d26a53b4",
      measurementId: "G-5X9E365468",
    };
    // Initialize Firebase
    initializeApp(firebaseConfig);
    this.db = getFirestore();
    /*this.docRef = await addDoc(collection(this.db, "people"), {
      name: this.user.name,
      activity: this.user.activity,
      links: []
    });*/
    /*const q = query(collection(this.db, "people"));
    onSnapshot(q, (querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let user = {};
        user.name = doc.data().name;
        user.activity = doc.data().activity;
        user.docId = doc.id;
        this.users.push(user);
      });
    });*/
  },
  mounted() {
    document.title = "Профориентация в цифре";
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  display: grid;
  grid-template-columns: 150px 1fr 300px;
}

nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.users {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

nav div,
nav a,
.navItem {
  border: 1px solid gray;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 5px 5px 5px gray;
}

.navItem {
  padding: 20px;
  margin-bottom: 10px;
}

.master {
  background-image: url("~@/assets/master.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
}

.chistov img {
  width: 100%;
}

a img {
  width: 100%;
}

input {
  padding: 10px;
  border-radius: 5px;
  outline: none;
  margin: 5px;
  text-align: center;
  font-size: 1rem;
}

button {
  display: inline-block;
  padding: 15px 25px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 15px;
  box-shadow: 0 5px #999;
}

button:hover {
  background-color: #3e8e41;
}

button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.user {
  border-bottom: 1px solid gray;
}
</style>
